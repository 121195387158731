<template>
  <div class="page-common page-setting-broker">
    <div class="page-params">
      <el-input
        class="param-item w180"
        type="text"
        placeholder="搜券商"
        clearable
        @clear="initPageData"
        v-model.trim="params.name"
      ></el-input>
      <el-select
        class="param-item"
        placeholder="请选择"
        v-model="params.category"
        @change="initPageData"
      >
        <el-option label="A股" :value="1"></el-option>
        <el-option label="港美股" :value="2"></el-option>
      </el-select>
      <el-button class="param-item" type="warning" icon="el-icon-refresh" @click="initPageData">刷新列表</el-button>
      <el-button class="param-item" icon="el-icon-sort" @click="sortConf.visible = true">调整顺序</el-button>
      <el-button class="param-item" type="primary" icon="el-icon-plus" @click="handleAddBroker">新增券商</el-button>
      <el-checkbox v-model="params.need_deleted" @change="initPageData">显示已删除的</el-checkbox>
    </div>
    <el-table
      size="medium"
      :data="tableData"
      v-loading="tableLoading"
      element-loading-spinner="el-icon-loading"
    >
      <el-table-column align="center" label="ID" prop="id" width="60"></el-table-column>
      <el-table-column align="center" label="券商名称" prop="name"></el-table-column>
      <el-table-column align="center" label="icon">
        <template #default="{ row }">
          <img class="broker-icon" :src="row.icon" alt="xgb-broker" />
        </template>
      </el-table-column>
      <el-table-column align="left" label="一句话简介" prop="desc"></el-table-column>
      <el-table-column align="center" label="分类">
        <template #default="{ row }">
          <span v-if="row.category === 1">A股</span>
          <span v-if="row.category === 2">港美股</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="更新时间">
        <template #default="{ row }">
          <span>{{ row.update_time_text }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="更新人" prop="update_user_name"></el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="{ row, $index }">
          <el-button size="mini" @click="handleEditBroker(row, $index)">编辑</el-button>
          <el-popconfirm
            title="确认删除操作？"
            v-if="!row.is_deleted"
            @confirm="handleDeleteBroker(row, 1)"
          >
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
          <el-button v-else size="mini" type="warning" @click="handleDeleteBroker(row, 2)">恢复</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="新增/编辑券商"
      custom-class="medium-modal"
      :close-on-click-modal="false"
      v-model="editingVisible"
    >
      <el-form
        ref="broker"
        label-position="left"
        label-width="120px"
        :rules="rules_broker"
        :model="editingBroker"
      >
        <el-form-item label="券商名称" prop="name">
          <el-input v-model.trim="editingBroker.name"></el-input>
        </el-form-item>
        <el-form-item label="icon" prop="icon">
          <UploadQiniu v-model="editingBroker.icon" />
        </el-form-item>
        <el-form-item label="分类" prop="category">
          <el-select v-model="editingBroker.category" placeholder="请选择">
            <el-option label="A股" :value="1"></el-option>
            <el-option label="港美股" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="一句话简介" prop="desc">
          <el-input v-model.trim="editingBroker.desc"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="uri">
          <el-input v-model.trim="editingBroker.uri"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="brokerConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="券商调序"
      custom-class="medium-modal"
      :close-on-click-modal="false"
      v-model="sortConf.visible"
    >
      <h1 class="sort-line">A股</h1>
      <Draggable v-model="sortConf.type1" item-key="id">
        <template #item="{ element }">
          <el-tag class="drag-tag">{{ element.name }}</el-tag>
        </template>
      </Draggable>
      <h1 class="sort-line">港美股</h1>
      <Draggable v-model="sortConf.type2" item-key="id">
        <template #item="{ element }">
          <el-tag class="drag-tag">{{ element.name }}</el-tag>
        </template>
      </Draggable>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="sortConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'
import Draggable from 'vuedraggable'
import { ElMessage as message } from 'element-plus'
import UploadQiniu from '@/components/base/upload-qiniu.vue'
import {
  fetchBrokerList,
  updateBrokerItem,
  deleteBrokerItem,
  fetchBrokerSort,
  updateBrokerSort
} from '@/domains/base-ddc'

const mockBroker = {
  name: '',
  icon: '',
  desc: '',
  category: 1,
  uri: 'https://xuangubao.cn',
  weight: 0,
  is_deleted: 0,
  update_time: 0,
  update_user_id: 0,
  update_user_name: ''
}

const rules_broker = {
  name: [{ required: true, message: '必填项' }],
  icon: [{ required: true, message: '必填项' }],
  desc: [{ required: true, message: '必填项' }],
  uri: [{ required: true, message: '必填项' }],
  category: [{ required: true, message: '必填项' }]
}

export default {
  name: 'SettingBroker',
  components: { UploadQiniu, Draggable },
  data() {
    return {
      rules_broker,
      params: {
        name: '',
        category: 1,
        need_deleted: true
      },
      tableData: [],
      editingVisible: false,
      editingBroker: cloneDeep(mockBroker),
      sortConf: {
        visible: false,
        type1: [],
        type2: []
      },
      tableLoading: false
    }
  },
  mounted() {
    this.initPageData()
    this.initSortInfo()
  },
  methods: {
    initSortInfo() {
      fetchBrokerSort({}).then((res) => {
        if (res && res['1'] && res['1'].length) {
          this.sortConf.type1 = res['1']
        }
        if (res && res['2'] && res['2'].length) {
          this.sortConf.type2 = res['2']
        }
      })
    },
    initPageData() {
      this.tableLoading = true
      fetchBrokerList(this.params)
        .then((res) => {
          if (res.items && res.items.length) {
            res.items.forEach((v) => {
              v.update_time_text = formatDate(v.update_time, 'YYYY-MM-DD HH:mm')
            })
            this.tableData = res.items
          } else {
            this.tableData = []
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleAddBroker() {
      this.editingBroker = cloneDeep(mockBroker)
      this.editingVisible = true
    },
    handleEditBroker(row, index) {
      console.log('handleEditBroker', row.id, index)
      this.editingBroker = cloneDeep(row)
      this.editingVisible = true
    },
    handleDeleteBroker(row, operate) {
      console.log('handleDeleteBroker', row.id)
      deleteBrokerItem({
        id: row.id,
        operate
      })
        .then(() => {
          message.success('操作成功')
          this.initPageData()
          this.initSortInfo()
        })
        .catch((err) => {
          message.error(JSON.stringify(err))
        })
    },
    brokerConfirm() {
      const formref = this.$refs.broker
      formref.validate((ok) => {
        if (!ok) return
        console.log(JSON.stringify(this.editingBroker, null, 2))
        updateBrokerItem(this.editingBroker)
          .then(() => {
            message.success('已保存')
            this.editingVisible = false
            this.initPageData()
            this.initSortInfo()
          })
          .catch((err) => {
            message.error(JSON.stringify(err))
          })
      })
    },
    sortConfirm() {
      const item1 = this.sortConf.type1.map((v) => v.id).join(',')
      const item2 = this.sortConf.type2.map((v) => v.id).join(',')
      const body = {
        order_str_list: [item1, item2]
      }
      updateBrokerSort(body)
        .then(() => {
          message.success('已保存')
          this.initPageData()
          this.initSortInfo()
          this.sortConf.visible = false
        })
        .catch((err) => {
          message.error(JSON.stringify(err))
        })
    }
  }
}
</script>
<style lang="scss">
.page-setting-broker {
  position: relative;
  .broker-icon {
    height: auto;
    max-width: 100px;
  }
  .sort-line {
    font-size: 16px;
    font-weight: 600;
    margin-top: 16px;
    padding-left: 10px;
  }
}
</style>
