<template>
  <div class="page-open-account">
    <el-tabs v-model="cuttentTab">
      <el-tab-pane name="broker" label="券商设置">
        <AccountBroker />
      </el-tab-pane>
      <el-tab-pane name="banner" label="banner设置">
        <AccountBanner />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script lang="ts">
import AccountBroker from '@/views/settings/account-broker.vue'
import AccountBanner from '@/views/settings/account-banner.vue'

export default {
  name: 'OpenAccount',
  components: {
    AccountBroker,
    AccountBanner
  },
  data() {
    return {
      cuttentTab: 'broker'
    }
  }
}
</script>
<style lang="scss" scoped>
.page-open-account {
  position: relative;
}
</style>
