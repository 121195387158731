
import AccountBroker from '@/views/settings/account-broker.vue'
import AccountBanner from '@/views/settings/account-banner.vue'

export default {
  name: 'OpenAccount',
  components: {
    AccountBroker,
    AccountBanner
  },
  data() {
    return {
      cuttentTab: 'broker'
    }
  }
}
