<template>
  <div class="page-common page-setting-banner">
    <div class="page-params">
      <el-button
        class="param-item"
        type="info"
        icon="el-icon-sort"
        @click="sortConf.visible = true"
      >调整顺序</el-button>
      <el-button
        class="param-item"
        type="primary"
        icon="el-icon-plus"
        @click="handleAddBanner"
      >新增banner</el-button>
      <el-checkbox v-model="params.need_deleted" @change="initPageData">显示已删除的</el-checkbox>
    </div>
    <div class="banner-list">
      <div
        v-for="(banner, idx) in tableData"
        :key="banner.id"
        class="banner-item"
        :class="{ 'is-deleted': banner.is_deleted }"
      >
        <h1 class="banner-title">{{ banner.title }}</h1>
        <div>
          <el-button size="mini" @click="handleEditBanner(banner, idx)">编辑</el-button>
          <el-popconfirm
            v-if="!banner.is_deleted"
            @confirm="handleDeleteBanner(banner, 1)"
            title="确认删除操作？"
          >
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
          <el-button v-else size="mini" type="warning" @click="handleDeleteBanner(banner, 2)">恢复</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="新增/编辑banner"
      custom-class="medium-modal"
      :close-on-click-modal="false"
      v-model="editingVisible"
    >
      <el-form
        label-width="120px"
        ref="banner"
        :rules="rules_banner"
        label-position="left"
        :model="editingBanner"
      >
        <el-form-item label="券商名称" prop="title">
          <el-input v-model.trim="editingBanner.title"></el-input>
        </el-form-item>
        <el-form-item label="icon" prop="image_uri">
          <UploadQiniu v-model="editingBanner.image_uri" />
        </el-form-item>
        <el-form-item label="链接" prop="uri">
          <el-input v-model.trim="editingBanner.uri"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="bannerConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="banner调序"
      custom-class="medium-modal"
      :close-on-click-modal="false"
      v-model="sortConf.visible"
    >
      <Draggable v-model="tableData" item-key="id">
        <template #item="{ element }">
          <el-tag class="drag-tag">{{ element.title }}</el-tag>
        </template>
      </Draggable>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="sortConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'
import Draggable from 'vuedraggable'
import { ElMessage as message } from 'element-plus'
import UploadQiniu from '@/components/base/upload-qiniu.vue'
import { fetchBannerList, updateBannerItem, deleteBannerItem, updateBannerSort } from '@/domains/base-ddc'

const mockBanner = {
  title: '',
  image_uri: '',
  uri: 'https://xuangubao.cn',
  weight: 0,
  is_deleted: 0,
  update_time: 0,
  update_user_id: 0,
  update_user_name: ''
}

const rules_banner = {
  title: [{ required: true, message: '必填项' }],
  image_uri: [{ required: true, message: '必填项' }],
  uri: [{ required: true, message: '必填项' }]
}

export default {
  name: 'SettingBanner',
  components: { UploadQiniu, Draggable },
  data() {
    return {
      rules_banner,
      params: {
        need_deleted: true
      },
      tableData: [],
      editingVisible: false,
      editingBanner: cloneDeep(mockBanner),
      sortConf: {
        visible: false
      }
    }
  },
  mounted() {
    this.initPageData()
  },
  methods: {
    initPageData() {
      fetchBannerList(this.params).then((res) => {
        if (res.items && res.items.length) {
          res.items.forEach((v) => {
            v.update_time_text = formatDate(v.update_time, 'YYYY-MM-DD HH:mm')
          })
          this.tableData = res.items
        } else {
          this.tableData = []
        }
      })
    },
    handleAddBanner() {
      this.editingBanner = cloneDeep(mockBanner)
      this.editingVisible = true
    },
    handleEditBanner(row, index) {
      console.log('handleEditBanner', row.id, index)
      this.editingBanner = cloneDeep(row)
      this.editingVisible = true
    },
    handleDeleteBanner(row, operate) {
      console.log('handleDeleteBanner', row.id)
      deleteBannerItem({
        id: row.id,
        operate
      })
        .then(() => {
          message.success('操作成功')
          this.initPageData()
        })
        .catch((err) => {
          message.error(JSON.stringify(err))
        })
    },
    bannerConfirm() {
      const formref = this.$refs.banner
      formref.validate((ok) => {
        if (!ok) return
        console.log(JSON.stringify(this.editingBanner, null, 2))
        updateBannerItem(this.editingBanner)
          .then(() => {
            message.success('已保存')
            this.initPageData()
            this.editingVisible = false
          })
          .catch((err) => {
            message.error(JSON.stringify(err))
          })
      })
    },
    sortConfirm() {
      const body = {
        order_str: this.tableData.map((v) => v.id).join(',')
      }
      updateBannerSort(body)
        .then(() => {
          message.success('已保存')
          this.initPageData()
          this.sortConf.visible = false
        })
        .catch((err) => {
          message.error(JSON.stringify(err))
        })
    }
  }
}
</script>
<style lang="scss">
.banner-list {
  background: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0 20px;
  width: 100%;
  .banner-item {
    background: #fff;
    border-radius: 10px;
    margin: 0 20px 20px 0;
    padding: 20px;
    text-align: center;
    &.is-deleted {
      opacity: 0.7;
      .banner-title {
        color: #f33;
      }
    }
  }
  .banner-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
</style>
